import React, { Component } from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';

class HowThisWorks extends Component {
  render() {
    return (
      <div className="how-this-works mt-3 mb-3 pt-5 pb-5">
        <Container>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>
                How this works
              </h3>
              <Row className="mt-5">
                <Col sm={{ size: 1 }}>
                  <h3>1)</h3>
                </Col>
                <Col sm="auto">
                  <h3>
                    We send you a question everyday for 30 days with examples
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col sm={{ size: 11, offset: 1 }}>
                  <h4 className="text-muted">
                    get behavioral interview questions asked by Fortune 500
                    companies everyday completed with examples and pro tips
                  </h4>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col sm={{ size: 1 }}>
                  <h3>2)</h3>
                </Col>
                <Col sm="auto">
                  <h3>Help you craft your own response</h3>
                </Col>
              </Row>
              <Row>
                <Col sm={{ size: 11, offset: 1 }}>
                  <h4 className="text-muted">
                    each question is completed with examples using STAR
                    technique and pro tips designed to help you craft your own
                    response
                  </h4>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col sm={{ size: 1 }}>
                  <h3>3)</h3>
                </Col>
                <Col sm="auto">
                  <h3>
                    <a href="/upgrade">Download entire question set</a>
                  </h3>
                </Col>
                <Col sm={{ size: 1 }}>
                  <h4>
                    <Badge color="warning">PREMIUM</Badge>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col sm={{ size: 11, offset: 1 }}>
                  <h4 className="text-muted">
                    Have an interview soon? No worries, for $9 you can download
                    the entire question set with 30 questions
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HowThisWorks;
