import React, { Component } from 'react';
import { UncontrolledAlert, Button, Container, Jumbotron, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

function SubscribeAlert({ showAlert, isSuccess, errorMessage }) {
    if (showAlert == true) {
        if (isSuccess == true) {
            return (
                <UncontrolledAlert color="success">
                    Successfully subscribed!
                </UncontrolledAlert>
            )
        } else {
            return (
                <UncontrolledAlert color="danger">
                    Error: {errorMessage}
                </UncontrolledAlert>
            )
        }
    } else {
        return null;
    }
}

class Email extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
            isSuccess: false,
            errorMessage: '',
        }

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    }

    handleValidSubmit(event, values) {
        const payload = {
            email: values.email
        }
        fetch(process.env.REACT_APP_BASE_URL + '/api/users', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        }).then((response) => {
            if (response.status == 200) {
                this.setState({
                    showAlert: true,
                    isSuccess: true,
                })
            } else {
                this.setState({
                    showAlert: true,
                    isSuccess: false,
                    errorMessage: response.statusText
                })
            }
        }).catch((err) => {
            this.setState({
                showAlert: true,
                isSuccess: false,
                errorMessage: err.errorMessage
            })
        })
    }

    handleInvalidSubmit(event, values) {

    }

    render() {
        return (
            <div>
                <SubscribeAlert showAlert={this.state.showAlert} isSuccess={this.state.isSuccess} errorMessage={this.state.errorMessage} />
                <Jumbotron className="bg-primary">
                    <h1 className="display-3 text-white">Daily Interview Prep</h1>
                    <p className="lead mt-4 text-white">Get exceptionally good at behavioral interview by practicing one a day!</p>
                    <Container className="mt-4">
                        <Col lg={{ size: 8, offset: 2 }}>
                            <AvForm onValidSubmit={this.handleValidSubmit}>
                                <AvField style={{ height: 50 }} name="email" type="email" required />
                                <Button
                                    style={{ height: 50, fontSize: '18px', fontWeight: 'bold' }}
                                    color="success"
                                    className="btn-block">Subscribe Now</Button>
                            </AvForm>
                        </Col>
                        <p className="pt-2 text-white"><small>No spam, ever. Easy unsubscribe</small></p>
                    </Container>
                </Jumbotron>
            </div>
        );
    };
}

export default Email;