import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row className="mt-sm-3">
                        <Col xs="4">
                            <h6><a href="">© Copyright 2020 Daily Interview Prep</a></h6>
                        </Col>
                        <Col xs="4">
                            <h5>Privacy Policy</h5>
                        </Col>
                        <Col xs="4">
                            <h5>Terms of service</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Footer;