import React, { Component } from 'react';
import { Container, Row, Col, Badge } from 'reactstrap';
import ReactMarkDown from 'react-markdown';

class Sample extends Component {
    /*render() {
        const input = `### Tell me about a time where you had conflict with another coworker.
---
#### STAR
1. Situation:
Orci eu lobortis elementum nibh tellus molestie nunc. Dictum at tempor commodo ullamcorper a lacus vestibulum sed. Vivamus at augue eget arcu dictum varius duis. In fermentum posuere urna nec.

2. Task:
Odio eu feugiat pretium nibh ipsum. Erat velit scelerisque in dictum.

3. Action:
Orci eu lobortis elementum nibh tellus molestie. Curabitur gravida arcu ac tortor. Egestas integer eget aliquet nibh praesent tristique magna sit. Aliquam eleifend mi in nulla posuere sollicitudin

4. Result:
Amet venenatis urna cursus eget nunc. Malesuada fames ac turpis egestas maecenas pharetra convallis posuere. Vitae purus faucibus

#### Pro Tips
* Be clear and specific on a time when you actually had a conflict with a coworker, if you sugarcoat this too much or pick something too trivial, hiring manager and recruiters will see that
* Be smart about what conflict you want to share, does not need to be a huge fight or shouting match, pick a time when you and coworker didn’t see eye to eye on something or did not align on the same idea
* Don’t get defensive over this, we have all had situations where we did not agree with a coworker, the key is show that you can collaborate with a coworker and find a path forward even when there’s conflict
`;
        return (
            <div style={{ backgroundColor: 'white' }} className="mt-3 mb-3 pt-5 pb-5">
                <Container>
                    <Row >
                        <Col xs={{ size: 10, offset: 1 }}>
                            <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>Free Sample</h3>
                            <ReactMarkDown source={input} unwrapDisallowed={true} />
                        </Col>

                    </Row>

                </Container>
            </div>
        );
    };*/
    render() {
        return (
            <div style={{ backgroundColor: 'white' }} >
                <Container>
                    <Row>
                        <Col className="mt-5 mb-5" xs={{ size: 10, offset: 1 }}>
                            <h1 className="text-center">Free Sample</h1>
                            <br />
                            <h4 id="tell-me-about-a-time-when-you-were-under-a-lot-of-pressure.-how-did-you-get-through-it">Tell me about a time when you were under a lot of pressure. How did you get through it?</h4>
                            <br />
                            <h5 id="example">Example</h5>
                            <p><strong>Situation:</strong> There was a top down mandate to complete a project for a client that normally would take 2 months in 2 weeks</p>
                            <p><strong>Task:</strong> I was responsible for building the website</p>
                            <p><strong>Action:</strong> Knowing that it is impossible for complete everything as planned before, I went to talk to my manager and worked out given the compressed timeline, what is absolutely needed in 2 weeks and what can be a follow up. I also worked with my manager to see what else on my plate that can be reprioritized until after the crunch time.</p>
                            <p><strong>Result:</strong> I was able to arrive at a scope for the project that was possible to deliver in 2 weeks, focused on it and got it done. The client was impressed with the quick turn around and thought the tradeoffs made were very reasonable given new timeline.</p>

                            <p><strong>Pro Tips</strong></p>
                            <ul>
                                <li>
                                    <p>Every job has their moments of stress and pressure, interviewers want to see how you perform and react under pressure.</p>
                                </li>
                                <li>
                                    <p>They want to see if you are someone that throw your hands up and avoid challenges or you focus on looking for solutions</p>
                                </li>
                                <li>
                                    <p>Avoid resentment over previous stressful or high pressure moments even if you had all the rights to be, those are in the past and right now your goal is to focus on landing your next job</p>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Sample;