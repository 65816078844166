import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
} from 'react-router-dom';
import log from 'loglevel';
import { PrivateRoute } from './components/PrivateRoute';
import Main from './components/MainComponent';
import Admin from './components/AdminComponent';
import Footer from './components/FooterComponent';
import Header from './components/HeaderComponent';
import Unsubscribe from './components/UnsubscribeComponent';
import Verify from './components/VerifyComponent';
import Premium from './components/PremiumComponent';
import PaymentStatus from './components/PaymentStatusComponent';
import UpdatePayment from './components/UpdatePaymentComponent';

function App() {
  log.setLevel(process.env.REACT_APP_LOG_LEVEL, true);
  log.info('React env: ' + process.env.NODE_ENV);
  var adminEnabled = process.env.REACT_APP_ADMIN_ENABLED == 'true';
  var isLive = process.env.REACT_APP_IS_LIVE == 'true';
  console.log(`Islive: ${isLive}`);
  console.log(process.env.REACT_APP_BASE_URL);
  if (isLive) {
    return (
      <Router>
        <div className="page-container">
          <Header />
          <div className="content-wrap">
            <Switch>
              <PrivateRoute
                exact
                path="/admin"
                component={Admin}
                isAdmin={adminEnabled}
              />
              <Route path="/unsubscribe" component={Unsubscribe} />
              <Route path="/verify" component={Verify} />
              <Route path="/upgrade" component={Premium} />
              <Route path="/update" component={UpdatePayment} />
              <Route
                path="/payment/success"
                render={(props) => (
                  <PaymentStatus {...props} status={'success'} />
                )}
              />
              <Route
                path="/payment/failed"
                render={(props) => (
                  <PaymentStatus {...props} status={'failed'} />
                )}
              />
              <Route path="/" component={Main} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
  return <div>WIP</div>;
}

export default App;
