import React, { useState, useEffect } from 'react';
import {
    useLocation
} from "react-router-dom";

function Unsubscribe() {
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    let params = new URLSearchParams(useLocation().search);
    let token = params.get("unsubscribeToken");

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + '/api/unsubscribe?unsubscribeToken=' + token, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.status == 200) {
                setIsLoading(false);
                setIsSuccess(true);

            } else {
                this.setIsLoading(false);
                this.setIsSuccess(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            setIsSuccess(false);
        })
    })

    if (isLoading) {
        return (
            <p>Loading</p>
        )
    } else {
        if (isSuccess) {
            return (
                <p>Success</p>
            )
        } else {
            return (
                <p>Error</p>
            )
        }
    }


}

export default Unsubscribe;