import React, { Component } from 'react';
import {
  Container,
  Col,
  Row,
  Jumbotron,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardDeck,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import log from 'loglevel';
import { Redirect } from 'react-router-dom';
import { auth } from '../auth';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Banner(props) {
  return (
    <div>
      <Jumbotron className="bg-primary text-white">
        <h3 className="display-3">Upgrade to premium plan</h3>
        <p className="lead">
          Continue preparing for your interviews with one these plans
        </p>
        <p className="lead">
          <Button color="btn btn-success" onClick={props.onClick}>
            See price and learn more
          </Button>
        </p>
      </Jumbotron>
    </div>
  );
}

function Packages(props) {
  return (
    <div ref={props.myRef}>
      <CardDeck>
        <Card className="card border-success">
          <CardHeader className="lead">Subscribe</CardHeader>
          <CardBody className="d-flex flex-column">
            <CardTitle>
              <h3>Free</h3>
            </CardTitle>
            <CardText>
              <ul>
                <li>1 new question everyday</li>
                <li>Completed with examples and pro tips</li>
                <li>
                  <b>Best for:</b> planning on job search, no interviews
                  scheduled yet
                </li>
              </ul>
            </CardText>
            <Button
              className="btn-warning btn-block signup-button mt-auto"
              onClick={props.clickSubscribe}
            >
              Sign up
            </Button>
          </CardBody>
        </Card>

        <Card className="card border-success">
          <CardHeader className="lead">Full pack</CardHeader>
          <CardBody className="d-flex flex-column">
            <CardTitle>
              <h3>$9</h3>
            </CardTitle>
            <CardText>
              <ul>
                <li>30 questions all at once</li>
                <li>Completed with examples and pro tips</li>
                <li>One time payment</li>
                <li>
                  <b>Best for:</b> Get ready fast, interviews already scheduled
                </li>
                <li>
                  (Disclaimer) The questions in the full pack are the same as
                  the ones we send you each day but you will have access to all
                  of them at once
                </li>
              </ul>
            </CardText>
            <Button
              className="btn-warning btn-block signup-button mt-auto"
              onClick={props.clickFullPackage}
            >
              Purchase for $9
            </Button>
          </CardBody>
        </Card>
      </CardDeck>
    </div>
  );
}

function Faq() {
  var input = '';
  return (
    <div>
      <h1 className="text-center">FAQ</h1>
      <br />
      <h3 id="when-will-i-get-charged-if-i-subscribed-to-the-monthly-plan">
        1. When will I get charged if I subscribed to the monthly plan?
      </h3>
      <p>
        You will be charged every month on the day you’ve upgraded your plan. If
        you have upgraded before the 7 days free trial has ended, you will not
        be charged until the free trial has ended.
      </p>
      <br />
      <h3 id="how-do-i-cancel-my-subscription-if-i-have-found-a-job-and-no-longer-need-daily-interview-prep">
        2. How do I cancel my subscription if I have found a job and no longer
        need Daily Interview Prep?
      </h3>
      <p>
        This is great news! We hope the reason anyone cancels Daily Interview
        Prep is because they have found a job. You can cancel anytime by either
        unsubscribing at the bottom of any email or you can email us at{' '}
        <a href="mailto:cancel@interviewprepdaily.com">
          cancel@interviewprepdaily.com
        </a>
        . You will continue receiving content until the end of your subscription
      </p>
      <br />
      <h3 id="should-i-get-the-monthly-subscription-or-pay-once-for-the-get-ready-pack">
        3. Should I get the monthly subscription or pay once for the full pack?
      </h3>
      <p>
        Depends where you are in your job search, if you are early on and have
        sometime before actual interviews you can choose the monthly
        subscription which gives you a lower per question price ($0.30). The
        full pack is a better option if you have an interview scheduled soon and
        would want to power through a bunch of questions to get ready fast. Both
        plans deliver the same question and guides.
      </p>
      <br />
      <h3 id="what-happens-if-i-subscribed-to-monthly-plan-but-want-to-get-all-the-questions-immediately">
        4. What happens if I subscribed to monthly plan but want to get all the
        questions immediately?
      </h3>
      <p>
        You are welcome to purchase the full pack and gain access to 60
        questions immediately. Note that you will not get a refund from the
        monthly subscription and the questions you get from the full pack are
        the same as the ones you would have gotten from your monthly
        subscription.
      </p>
    </div>
  );
}

function FaqFull() {
  //Faq for full package upgrade only, no subscription
  var input = '';
  return (
    <div>
      <h1 className="text-center">FAQ</h1>
      <br />
      <h3>
        1. How will I receive the questions once I upgraded to the full pack?
      </h3>
      <p>
        The email that you entered when you upgraded would be the email that the
        full pack will be sent to.
      </p>
      <br />
      <h3>2. I paid for the upgrade but did not receive an email?</h3>
      <p>
        Check the spam or junk folder first to make sure it is not there. If you
        still can't find the email, contact us at{' '}
        <a href="mailto:cancel@interviewprepdaily.com">
          cancel@interviewprepdaily.com
        </a>{' '}
        and we will make sure you get the full pack
      </p>
      <br />
      <h3>
        3. How do I cancel my subscription if I have found a job and no longer
        need Daily Interview Prep?
      </h3>
      <p>
        This is great news! We hope the reason anyone cancels Daily Interview
        Prep is because they have found a job. You can cancel anytime by either
        unsubscribing at the bottom of any email or you can email us at{' '}
        <a href="mailto:cancel@interviewprepdaily.com">
          cancel@interviewprepdaily.com
        </a>
        . You will continue receiving content until the end of your subscription
      </p>
      <br />
      <h3>4. Should I upgrade to the full pack?</h3>
      <p>
        Depends where you are in your job search, if you are early on and have
        sometime before actual interviews you can choose the free monthly
        subscription. The full pack is a better option if you have an interview
        scheduled soon and would want to power through a bunch of questions to
        get ready fast.
      </p>
      <br />
      <h3>
        5. Are the questons from the full pack the same as the ones I have been
        getting everyday?
      </h3>
      <p>
        Yes, the full pack gives you access to all 30 questions at once while
        the free daily email gives you 1 question a day. If you have an
        interview coming up soon, upgrading to the full pack is the way to go
      </p>
    </div>
  );
}

function fetchCheckoutSession(type, email) {
  let promise = new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        '/api/payment/checkout-session?type=' +
        type +
        '&email=' +
        encodeURIComponent(email),
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
      .then(function (response) {
        log.info('Response' + response);
        resolve(response.json());
      })
      .catch((err) => {
        log.error('Error: ' + err);
        reject(err);
      });
  });
  return promise;
}

function fetchUserUpgrade(email, mode) {
  let promise = new Promise((resolve, reject) => {
    fetch(
      process.env.REACT_APP_BASE_URL +
        '/api/users/upgrade?email=' +
        encodeURIComponent(email) +
        '&mode=' +
        mode,
      {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: auth.authHeader(),
        },
      }
    )
      .then(function (response) {
        log.info('Response' + response);
        resolve(response.json());
      })
      .catch((err) => {
        log.error('Error: ' + err);
        reject(err);
      });
  });

  return promise;
}

class Premium extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      email: new URLSearchParams(this.props.location.search).get('email') || '',
      modal: false,
      mode: '',
      errorMessage: '',
    };
    this.handleButtonClicked = this.handleButtonClicked.bind(this);
    this.handleSubscriptionClicked = this.handleSubscriptionClicked.bind(this);
    this.handleFullPackageClicked = this.handleFullPackageClicked.bind(this);
    this.handlePaymentRedirect = this.handlePaymentRedirect.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal,
      errorMessage: '',
    });
  }

  handleButtonClicked() {
    window.scrollTo(0, this.myRef.current.offsetTop);
  }
  handleSubscriptionClicked() {
    this.setState({ mode: 'subscription' });
    //this.toggleModal();
    this.props.history.push('/');
  }

  handleFullPackageClicked() {
    this.setState({ mode: 'full' });
    this.toggleModal();
  }

  async handlePaymentRedirect(e) {
    e.preventDefault();
    const stripe = await stripePromise;
    const upgrade = await fetchUserUpgrade(this.state.email, this.state.mode);
    if (!upgrade.error) {
      if (!upgrade.upgrade) {
        const response = await fetchCheckoutSession(
          this.state.mode,
          this.state.email
        );
        const sessionId = response.id;
        const result = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });
        if (result.error) {
          this.setState({ errorMessage: result.error });
        }
      } else {
        if (this.state.mode == 'subscription') {
          this.setState({
            errorMessage:
              'You have already upgraded to premium account, email contact@interviewprepdaily.com if you have any issues',
          });
        } else if (this.state.mode == 'full') {
          this.setState({
            errorMessage:
              'The entire interview prep package should have been emailed to you, email contact@interviewprepdaily.com if you have any issues',
          });
        }
      }
    } else {
      this.setState({
        errorMessage:
          'You have not signed up yet, go to home page to sign up first',
      });
    }
  }

  render() {
    var modalBody =
      this.state.mode == 'subscription'
        ? 'Email that you wish to upgrade the account to'
        : 'Email that you wish to receive the full package';
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Enter email</ModalHeader>
          <ModalBody>{modalBody}</ModalBody>
          <Form onSubmit={this.handlePaymentRedirect}>
            <FormGroup>
              <Col sm={12}>
                <Input
                  value={this.state.email}
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <Button className="mt-3">Submit</Button>
              </Col>
              <Col>
                <Label className="mt-3">{this.state.errorMessage}</Label>
              </Col>
            </FormGroup>
          </Form>
        </Modal>
        <Container className="mt-3 mb-3">
          <Row>
            <Col>
              <Banner onClick={this.handleButtonClicked} />
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg={{ size: 10, offset: 1 }}>
              <Packages
                myRef={this.myRef}
                clickSubscribe={this.handleSubscriptionClicked}
                clickFullPackage={this.handleFullPackageClicked}
              />
            </Col>
          </Row>
          <Row className="mt-5 mb-5 ml-5 mr-5">
            <Col>
              <FaqFull />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Premium;
