import React, { Component } from 'react';
import { Jumbotron, Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class PaymentStatus extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        var header = this.props.status == 'success' ? 'Payment is successful' : 'Payment failed';
        var subtitle = this.props.status == 'success' ? 'You are all set to recieve contents from Daily Interview Prep' : 'Something went wrong, follow the upgrade link to try again.'
        var link = this.props.status == 'success' ? '/home' : '/subscribe';
        var buttonTitle = this.props.status == 'success' ? 'Home' : 'Upgrade';
        return (
            <div>
                <Container className="pt-5">
                    <Row>
                        <Col lg={{ size: 8, offset: 2 }}>
                            <Jumbotron>
                                <h1>{header}</h1>
                                <p className="lead">{subtitle}</p>
                                <Link to={link}>
                                    <Button className="mt-3" color="primary">{buttonTitle}</Button>
                                </Link>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    };
};

export default PaymentStatus;