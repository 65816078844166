import React, { Component } from 'react';
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { auth } from '../auth';
import log from 'loglevel';

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            input: ''
        }
        this.handleTitle = this.handleTitle.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleTitle(e) {
        const title = e.target.value;
        log.info(title);
        this.setState({
            title: title
        })
    }

    handleInput(e) {
        const input = e.target.value;
        log.info(input);
        this.setState({
            input: input
        })

    }

    handleSubmit(e) {
        e.preventDefault();
        const title = this.state.title;
        const input = this.state.input;
        var payload = {
            title: title,
            content: input
        };
        fetch(process.env.REACT_APP_BASE_URL + '/api/questions', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth.authHeader()
            },
            body: JSON.stringify(payload)
        }).then(function (response) {
            log.info('Response' + response);
        }).catch((err) => {
            log.error('Error: ' + err);
        });
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                        <Col xs={12}>
                            <Label for="editorText" xs={5}>Editor</Label>
                        </Col>
                        <Col xs={6}>

                            <Input className="edit-input" type="textarea" placeholder="Title" name="text" id="titleText" onChange={this.handleTitle} />
                            <Input type="textarea" placeholder="Content" name="text" id="editorText" onChange={this.handleInput} />
                        </Col>
                        <Col xs={6}>
                            <div className="edit-display" dangerouslySetInnerHTML={{ __html: this.state.input }}></div>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Button>Submit</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default Edit;