import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

class Faq extends Component {
    render() {
        return (
            <div className="how-this-works mt-3 mb-3 pt-5 pb-5">
                <Container>
                    <Row>
                        <Col xs={{ size: 10, offset: 1 }}>
                            <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>FAQ</h3>
                            <Row className="mt-5">
                                <Col sm={{ size: 1 }}>
                                    <h4>1)</h4>
                                </Col>
                                <Col sm="auto">
                                    <h4>How is this website going to help me?</h4>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={{ size: 11, offset: 1 }}>
                                    <p>Get personalized behavioral questions asked by Fortune 500 companies everyday</p>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col sm={{ size: 1 }}>
                                    <h4>2)</h4>
                                </Col>
                                <Col sm="auto">
                                    <h4>Should I memorize the answers to these questions?</h4>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={{ size: 11, offset: 1 }}>
                                    <p>Short answer is no. The longer answer is that the key to success is to be able to recall past experiences and situations during the interview effectively by preparing a few of them through the daily interview prep program and able to adapt those experiences to the specific questions so that you can more easily form your answers during the interview. Trying to memorize word to word is going to make you look too rehearsed, and unable to react to new questions.</p>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col sm={{ size: 1 }}>
                                    <h4>3)</h4>
                                </Col>
                                <Col sm="auto">
                                    <h4>I have found my dream job, how do I unsubscribe?</h4>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm={{ size: 11, offset: 1 }}>
                                    <p>Nothing makes us happier than someone canceling because they have found a job! you can unsubscribe anytime by clicking the "Unsubscribe" link at the bottom of the email.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Faq;