import React, { Component } from 'react';
import Home from './HomeComponent';
import Footer from './FooterComponent';


class Main extends Component {
    render() {
        return (
            <div className="bg-light">
                <Home />
                <Footer />
            </div>
        );
    }
}

export default Main;