import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

class Header extends Component {
    render() {
        return (
            <div className="header">
                <Container>
                    <Row>
                        <NavLink className="nav-link-inactive" to="/"><h2>Daily Interview Prep</h2></NavLink>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Header;