import React, { Component } from 'react';
import { Button, Col, Container, Row, Jumbotron } from 'reactstrap';
import log from 'loglevel';
import { auth } from '../auth';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function fetchUser(email) {
    let promise = new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_BASE_URL + '/api/users/stripe?email=' + encodeURIComponent(email), {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth.authHeader()
            }
        }).then(function (response) {
            log.info('Response' + response);
            resolve(response.json());
        }).catch((err) => {
            log.error('Error: ' + err);
            reject(err);
        });
    })

    return promise;
}

function fetchCheckoutSession(type, email) {
    let promise = new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_BASE_URL + '/api/payment/checkout-session?type=' + type + '&email=' + encodeURIComponent(email), {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            log.info('Response' + response);
            resolve(response.json());
        }).catch((err) => {
            log.error('Error: ' + err);
            reject(err);
        });
    })
    return promise;
}

class UpdatePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: new URLSearchParams(this.props.location.search).get("email") || '',
        }
        this.handleButtonClicked = this.handleButtonClicked.bind(this);

    }

    async handleButtonClicked() {
        const stripe = await stripePromise;
        const response = await fetchCheckoutSession('setup', this.state.email);
        const sessionId = response.id;
        const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });
    }

    render() {
        return (
            <div>
                <Container>
                    <Row className="mt-5">
                        <Col xs={12} md={{ size: 8, offset: 2 }} >

                            <Jumbotron>
                                <h1 className="display-3">Update payment</h1>
                                <p className="lead">Update your payment method to continue receiving daily interview prep</p>
                                <p className="lead">
                                    <Button color="primary" onClick={this.handleButtonClicked}>Update Payment</Button>
                                </p>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default UpdatePayment;