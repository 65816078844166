import React, { Component } from 'react';
import { Form, FormGroup, Label, Col, Input, Button } from 'reactstrap';
import { auth } from '../auth';
import log from 'loglevel';

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questions: []
        }
    }

    componentWillMount() {
        var headers = {
            'Authorization': auth.authHeader()
        }
        fetch(process.env.REACT_APP_BASE_URL + '/api/questions', {
            method: 'get',
            headers: headers
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                this.setState({
                    questions: data
                })
            })
            .catch((err) => {
                log.error(err);
            })
    }

    render() {
        return (
            <div>
                <ul>
                    {this.state.questions.map(question => (
                        <li key={question}>{question.title}</li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default Question;