import React, { Component } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import Edit from './EditComponent';
import Question from './QuestionComponent';

function RenderAdmin(props) {
    if (props.toggleEditor) {
        return (
            <div>
                <Edit />
            </div>
        );
    } else {
        return (
            <div>
                <Question />
            </div>
        )
    }
}

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleEditor: true
        }
        this.toggleEditor = this.toggleEditor.bind(this);
    }

    toggleEditor() {
        this.setState({
            toggleEditor: !this.state.toggleEditor
        })
    }

    render() {
        return (
            <div className="bg-light">
                <Container className="pt-3">
                    <Button color="primary" onClick={this.toggleEditor}>{this.state.toggleEditor ? 'See all questions' : 'Add new question'}</Button>
                    <RenderAdmin toggleEditor={this.state.toggleEditor} />
                </Container>
            </div>
        );
    };
};

export default Admin;