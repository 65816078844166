import React, { Component } from 'react';
import { Input, Row, Col, Container, Button, Form, FormGroup, Label } from 'reactstrap';
import Faq from './FaqComponent';
import HowThisWorks from './HowThisWorksComponent';
import Sample from './SampleComponent';
import Email from './EmailComponent';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: '',
            display: '',
        };
    }

    render() {
        return (
            <div className="col-md-10 col-12 offset-md-1 pt-3">
                <Email/>
                <HowThisWorks />
                <Sample />
                <Faq />
            </div>
        );
    };
}

export default Home;